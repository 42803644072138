import { useI18n } from 'vue-i18n';

export type AggregateLevel = 'month' | 'week' | 'day';

export const useGlobalFilter = () => {
  // Composables
  const { t } = useI18n();
  const { getCo2byKWhByMeterId, getCO2Formated } = useCO2();
  const { formatNumberToIsoNumber } = useNumbers();
  // Methods
  const getAggregateLevelOption = (): { key: AggregateLevel; label: string }[] => {
    return [
      {
        key: 'month',
        label: t('global.month'),
      },
      {
        key: 'week',
        label: t('global.week'),
      },
      {
        key: 'day',
        label: t('global.day'),
      },
    ];
  };

  const getEnergyTypesByArea = (areaId: number) => {
    return areaStore()
      .getArea(areaId)
      ?.meters.map((meter) => ({
        label: t(`global.energy_type.${meter.meter_type.energy_type}`),
        key: meter.meter_type.energy_type,
      }));
  };

  // ===========================================================  Use for consumptions dropw down list ===========================================================
  const getConsumptionOptionList = [
    {
      key: 'quantity',
      label: 'kWh',
    },
    {
      key: 'cost',
      label: '€',
    },
    {
      key: 'co2',
      label: 'CO₂',
    },
  ];
  const getConsumptionDataList = (options: { consumption: number; cost: number; meterId: number }) => {
    const getCo2byKWh = computed(() => getCo2byKWhByMeterId(options.meterId));
    return {
      quantity: `${formatNumberToIsoNumber(options.consumption)} kWh`,
      cost: `${formatNumberToIsoNumber(options.cost)} €`,
      co2: `${getCO2Formated(options.consumption, getCo2byKWh.value)}`,
    };
  };

  // ===========================================================  Use for EPI dropw down list ===========================================================
  const getEPIOptionListByArea = (
    areaId: number,
    type: 'production' | 'fabrication_order' | 'aggregated_production' = 'production',
  ): { key: string; label: string }[] => {
    const area = areaStore().getArea(areaId);
    const unit = type === 'fabrication_order' ? (area?.fabrication_order_unit?.symbol ?? '--') : (area?.production_unit?.symbol ?? '--');
    const options = [
      {
        key: 'quantity',
        label: `kWh / ${unit}`,
      },
      {
        key: 'cost',
        label: `€ / ${unit}`,
      },
      {
        key: 'co2',
        label: `CO₂ / ${unit}`,
      },
    ];

    return options;
  };

  const getEPIDataList = (options: {
    type: 'production' | 'fabrication_order' | 'aggregated_production';
    consumption: number;
    cost: number;
    meterId: number;
  }) => {
    const { getAreaByMeterId } = areaStore();
    const getCo2byKWh = computed(() => getCo2byKWhByMeterId(options.meterId));
    const unit =
      options.type === 'fabrication_order'
        ? (getAreaByMeterId(options.meterId)?.fabrication_order_unit?.symbol ?? '--')
        : (getAreaByMeterId(options.meterId)?.production_unit?.symbol ?? '--');

    return {
      co2: `${getCO2Formated(options.consumption, getCo2byKWh.value)}/${unit}`,
      quantity: `${formatNumberToIsoNumber(options.consumption, 2)} kWh/${unit}`,
      cost: `${formatNumberToIsoNumber(options.cost, 2)} €/${unit}`,
    };
  };

  return {
    getAggregateLevelOption,
    getEnergyTypesByArea,
    getEPIOptionListByArea,
    getConsumptionOptionList,
    getConsumptionDataList,
    getEPIDataList,
  };
};
